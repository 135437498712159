import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJson from './locale/en.json'
import frJson from './locale/fr.json'

i18n.use(initReactI18next).init({
 resources: {
    en: { ...enJson },
    fr: { ...frJson },
 }, // Where we're gonna put translations' files
 lng: "fr", // Set the initial language of the App
});