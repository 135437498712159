import { header } from '../../portfolio'
import Navbar from '../Navbar/Navbar'
import { Avatar } from 'primereact/avatar';
import './Header.css'
import { Height } from '@material-ui/icons';
import "aos/dist/aos.css"
import AOS from 'aos';

const Header = () => {
  const { homepage, title } = header

  return (
    <header className='header center' data-aos="zoom-out">
      <div className='avatar_title'>
        <Avatar image="/img/profile.jpg" size="xlarge" shape="circle" style={{ width: '44px', height: '44px' }} />
        <h3>
          {homepage ? (
            <a href="#" className='link'>
              {title}
            </a>
          ) : (
            title
          )}
        </h3>
      </div>
      <Navbar />
    </header>
  )
}

export default Header
