const header = {
  homepage: 'sifeddinehadi',
  title: 'sifeddine.com',
}

const about = {
  name: 'Sif eddine HADI',
  role: 'Software Developer',
  description:
    'I am a passionate full-stack developer, with solid experience in web application design and development, I have had the opportunity to work on various projects, ranging from corporate websites to SaaS applications, through innovative prototypes. My problem-solving approach and team spirit have allowed me to successfully contribute to the development of tailor-made solutions that meet specific customer needs.',
  resume: '#',
  social: {
    linkedin: 'https://www.linkedin.com/in/sif-eddine-hadi-361037233/',
    github: 'https://github.com/sife22/',
  },
}

const projects = [
  {
    name: 'La Plume Curieuse',
    description:
      " : blog où nous pouvons partager différents articles.",
    stack: ['Html5', 'Css3', 'ReactJs - RTK', 'Laravel', 'MySQL', 'Git'],
    sourceCode: 'https://github.com/sife22',
    livePreview: '#',
  },
  {
    name: 'PureTalk-App',
    description:
      'Un projet qui utilise Reactjs et Firebase pour élaborer une application de chat conviviale.',
    stack: ['Html5', 'Css3', 'ReactJs', 'Firebase', 'Git'],
    sourceCode: 'https://github.com/sife22/puretalk-app',
    livePreview: 'https://puretalk-app.netlify.app/',
  },
  {
    name: 'LinkedIn Clone',
    description:
      'Clonage de la plateforme LinkedIn en utilisant ReactJs et Firebase',
    stack: ['Html5', 'Css3', 'ReactJs', 'Firebase', 'Git'],
    sourceCode: 'https://github.com/sife22/linkedin-clone',
    livePreview: 'https://linkedin-clone-reactjs-app.vercel.app/',
  },
  {
    name: 'Weather App',
    description:
      'Il s’agit d’un TP réalisé avec ReactJs. Les utilisateurs ont la possibilité de rechercher des informations météorologiques pour leurs différentes villes.',
    stack: ['Html5', 'Css3', 'JavaScript', 'ReactJs'],
    sourceCode: 'https://github.com/sife22/weather-reactjs',
    livePreview: 'https://weather-app-reactjs-master.netlify.app/',
  },
  // {
  //   name: 'Autobahn-pfe',
  //   description:
  //     'It is a small platform that manages a car rental agency, realized using Laravel...',
  //   stack: ['Html5', 'Css3', 'JavaScript', 'PHP', 'Laravel', 'MySQL', 'UML'],
  //   sourceCode: 'https://github.com/sife22/autobahn-pfe',
  //   livePreview: '#',
  // },
  // {
  //   name: 'And other projects',
  //   description:
  //     'And other projects using the techniques stack below',
  //   stack: [],
  //   sourceCode: '#',
  //   livePreview: '#',
  // },
]

const skills = [
  {
    name: 'HTML',
    logo: 'fa-brands fa-html5'
  },
  {
    name: 'CSS',
    logo: 'fa-brands fa-css3-alt'
  }, {
    name: 'Bootstrap',
    logo: 'fa-brands fa-bootstrap'
  }, {
    name: 'Material UI',
    logo: 'fa-solid fa-pen-nib'
  }, {
    name: 'JavaScript',
    logo: 'fa-brands fa-js'
  }, {
    name: 'PHP',
    logo: 'fa-brands fa-php'
  }, {
    name: 'Laravel',
    logo: 'fa-brands fa-laravel'
  }, {
    name: 'Symfony',
    logo: 'fa-brands fa-symfony'
  },
  {
    name: 'ReactJs',
    logo: 'fa-brands fa-react'
  },
  {
    name: 'Redux',
    logo: 'fa-brands fa-react'
  },
  {
    name: 'Redux TK',
    logo: 'fa-brands fa-react'
  }, {
    name: 'Firebase',
    logo: 'fa-solid fa-fire'
  },
  {
    name: 'Python',
    logo: 'fa-brands fa-python'
  }
  , {
    name: 'Flask',
    logo: 'fa-brands fa-python'
  }, {
    name: 'Django',
    logo: 'fa-brands fa-python'
  },
  {
    name: 'Wordpress',
    logo: 'fa-brands fa-wordpress-simple'
  },
  {
    name: 'Drupal',
    logo: 'fa-brands fa-drupal'
  }
  ,
  {
    name: 'MySQL',
    logo: 'fa-solid fa-database'
  }, {
    name: 'MongoDB',
    logo: 'fa-solid fa-database'
  }, {
    name: 'Git',
    logo: 'fa-brands fa-git-alt'
  },
  {
    name: 'Azure',
    logo: 'fa-solid fa-cloud'
  },
  ,
  {
    name: 'Docker',
    logo: ''
  },
  {
    name: 'Netlify',
    logo: ''
  },
  {
    name: 'Vercel',
    logo: ''
  },
  {
    name: 'Jira',
    logo: ''
  }
]

const contact = {
  email: 'sifeddinehadi22@gmail.com',
}

export { header, about, projects, skills, contact }
