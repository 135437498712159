import { useContext, useState } from 'react'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeContext } from '../../contexts/theme'
import { projects, skills, contact } from '../../portfolio'
import './Navbar.css'
import { useTranslation } from 'react-i18next'
import { Avatar } from 'primereact/avatar';



const Navbar = () => {

  const { t, i18n: { changeLanguage, language } } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(language)
  const handleChangeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  }


  const [{ themeName, toggleTheme }] = useContext(ThemeContext)
  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList)

  return (
    <nav className='center nav'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >

        {skills.length ? (
          <li className='nav__list-item'>
            <a
              href='#skills'
              onClick={toggleNavList}
              className='link link--nav'
            >
              {t('skills', { appName: "App for Translations" })}
            </a>
          </li>
        ) : null}

        {/* {projects.length ? (
          <li className='nav__list-item'>
            <a
              href='#projects'
              onClick={toggleNavList}
              className='link link--nav'
            >
              {t('projects', { appName: "App for Translations" })}
            </a>
          </li>
        ) : null} */}
        {contact.email ? (
          <li className='nav__list-item'>
            <a
              href='#contact'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Contact
            </a>
          </li>
        ) : null}
          <li className='nav__list-item'>
            <a
              href='https://github.com/sife22'
              target='_blank'
              // onClick={toggleNavList}
              className='link link--nav'
            >
              GitHub
            </a>
          </li>
      </ul>



      <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
        style={{ marginRight: "15px" }}
      >
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </button>
      <button
        type='button'
        onClick={handleChangeLanguage}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {currentLanguage === 'en' ? <Avatar image="https://img.icons8.com/?size=100&id=YwnngGdMBmIV&format=png&color=000000" size="xlarge" shape="circle" />
          : <Avatar image="https://img.icons8.com/?size=100&id=ShNNs7i8tXQF&format=png&color=000000" size="xlarge" shape="circle" />}
      </button>

      <button
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  )
}

export default Navbar
