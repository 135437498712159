import { contact } from '../../portfolio'
import './Contact.css'
import { Card } from 'primereact/card';
import { about } from '../../portfolio'
import { useTranslation } from 'react-i18next'
import AOS from 'aos';
import "aos/dist/aos.css"




const Contact = () => {

  const { t, i18n: { changeLanguage, language } } = useTranslation();

  const { social } = about

  if (!contact.email) return null

  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title' data-aos="zoom-out">Contact</h2>
      <a href="#contact" style={{ marginBottom: '10px' }} data-aos="zoom-out">
        <span type='button' className='btn btn--outline'>
          {t('callme', { appName: "App for Translations" })} +212 669 08 44 87
        </span>
      </a>
      <div style={{ display: 'flex' }} data-aos="zoom-out">
        <a href={`mailto:${contact.email}`} style={{ margin: '5px' }} data-aos="fade-in">
          <span type='button' className='btn btn--outline'>
            {t('emailme', { appName: "App for Translations" })}
          </span>
        </a>

        <a href={social.linkedin} style={{ margin: '5px' }} target='_blank'  data-aos="fade-in">
          <span type='button' className='btn btn--outline'>
            LinkedIn
          </span>
        </a>
        <a href={social.github} style={{ margin: '5px' }} target='_blank' data-aos="fade-in">
          <span type='button' className='btn btn--outline'>
            GitHub
          </span>
        </a>
      </div>
      {/* <a href="#contact" style={{ marginTop: '10px' }} data-aos="zoom-out">
        <span type='button' className='btn btn--outline'>
          {t('stayhere', { appName: "App for Translations" })} '{' www.sifeddine.com '}'
        </span>
      </a> */}
      <a href='https://x.com/sifeddinehadi' style={{ marginTop: '25px' }} target='_blank' data-aos="zoom-out">
        <span type='button' className='btn'>
          {t('whynot?', { appName: "App for Translations" })}
          <i class="fa-brands fa-x-twitter" style={{ marginLeft: '5px' }}></i>
        </span>
      </a>

    </section>
  )
}

export default Contact
