import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { about } from '../../portfolio'
import './About.css'
import { useTranslation } from 'react-i18next'
import AOS from 'aos';
import "aos/dist/aos.css"



const About = () => {
  const { name, role, description, resume, social } = about
  const { t, i18n: { changeLanguage, language } } = useTranslation();

  return (
    <div className='about center' id='about' data-aos="zoom-out">
      {name && (
        <h1 className='title'>
          {t('headerTitle', { appName: "App for Translations" })} <span className='about__name'>{name}.</span>
        </h1>
      )}

      {role && <h2 className='about__role'>{t('a', { appName: "App for Translations" })} {t('role', { appName: "App for Translations" })}.</h2>}
      <p className='about__desc'>{t('description', { appName: "App for Translations" })}</p>

     

      <div className='about__contact center'>
        {resume && resume !== "#" && (
          <a href={resume} >
            <span type='button' className='btn btn--outline'>
              {t('resume', { appName: "App for Translations" })}
            </span>
          </a>
        )}

        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
                target='_blank'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
                target='_blank'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
