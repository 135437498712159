import './Footer.css'
import { useTranslation } from 'react-i18next'

const Footer = () => {

  const { t, i18n: { changeLanguage, language } } = useTranslation();

  return (
    <footer className='footer' >
      <a
        href='#contact'
        className='link footer__link'
      >
        {t('morocco', { appName: "App for Translations" })}
      </a>
      <br></br>
      <a
        href='#contact'
        className='link footer__link'
      >
        {t('createdby', { appName: "App for Translations" })} Sif eddine HADI
      </a>
    </footer>
  )
}

export default Footer
